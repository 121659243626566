import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageSupport = () => (
  <Layout>
    <PageTitle
      title="Support"
      description="We're committed to providing customers with second-to-none customer service. Customer support is available 24/7, 365 days a year by both phone and email."
    ></PageTitle>
    <ContentBlock className="rich-text">
      <div className="max-w-4xl">
        <p>
          For support, please call <a href="tel:+18103205063">(810) 320-5063</a>
          , or email{" "}
          <a href="mailto:support@towbook.com">support@towbook.com</a>.
        </p>
        <p>
          Whether you&apos;re just starting to use our towing software, or need
          help learning a part of the system that you haven&apos;t used before,
          feel free to call us. Our friendly support staff is always available
          to help you use any part of Towbook.
        </p>
      </div>
      <h3>Knowledge Base</h3>
      <div className="max-w-4xl">
        <p>
          No time to train with us? No problem! Be sure to check out our
          knowledge base with written tutorials available to you at any time;
          from setup to special features, you’ll find many helpful tools here to
          allow you to get started on your own!
        </p>
        <a
          href="http://support.towbook.com"
          className="inline-block mt-3 rounded px-3 py-2 bg-yellow-500 hover:bg-yellow-400"
        >
          <span className="text-gray-800">Visit the Knowledge Base</span>
        </a>
      </div>
    </ContentBlock>
  </Layout>
)

export default PageSupport
